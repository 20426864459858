import React from 'react'

export const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <h1>Privacy Policy</h1>

    <p>
      <i>Last updated 01 July 2022</i>
    </p>

    <p>
      This Privacy Policy (hereinafter &quot;<strong>Policy</strong>&quot;)
      informs how Swarmia Oy processes personal data about its business
      customers, representatives of business customers, potential business
      customers and internet users visiting our website at
      https://www.swarmia.com/ (jointly &quot;<strong>Users</strong>&quot;).
    </p>

    <p>The key aspects of this Policy include the following:</p>
    <ul>
      <li>Personal data we collect and reasons why we collect it;</li>
      <li>How we use the personal data;</li>
      <li>How we share the personal data;</li>
      <li>How long we retain the personal data; and</li>
      <li>What rights Users have.</li>
    </ul>

    <h2>1. Data Controller</h2>

    <p>
      The data controller is Swarmia Oy (hereinafter &quot;Swarmia&quot;,
      &quot;we&quot;, &quot;us&quot; or &quot;our&quot;). Swarmia is responsible
      for ensuring that personal data is processed in compliance with this
      Policy and applicable data protection laws.
    </p>

    <p>Contact details of the data controller:</p>

    <blockquote>
      Swarmia Oy
      <br />
      Business ID: 3094736-9
      <br />
      Address: Lapinlahdenkatu 16, 00180 Helsinki, Finland
      <br />
      Email: hello@swarmia.com
    </blockquote>

    <h2>2. Processing of Personal Data</h2>

    <p>
      We collect personal data through different means, which are explained
      below. As a rule, personal data is collected directly from User in
      connection with the customer relationship or website activity. We may also
      collect contact details of potential business customers from third
      parties, such as through sales intelligence platforms.
    </p>

    <h3>2.1 Customer data</h3>

    <p>
      The following personal data is processed in connection with the customer
      relationship:
    </p>

    <ul>
      <li>
        User information, such as name, email address, job title, company name;
      </li>
      <li>
        Customer relationship details, such as contract, start and end date of
        customer relationship and services ordered;
      </li>
      <li>
        Billing information, such as bank account information, payments made and
        outstanding and bills delivered;
      </li>
      <li>
        Other business-related information which is not itself personal data but
        which, together with other information, may be personal data, such as
        number of developers in the company, issue tracker and source hosting
        service;
      </li>
      <li>
        Customer interaction, such as customer contacts, feedback, and
        complaints; and
      </li>
      <li>
        Information related to marketing communications through email, meetings,
        website and social media platforms.
      </li>
    </ul>

    <h3>2.2 Prospect data</h3>

    <p>
      We contact potential customers and provide them other relevant information
      about our Service. For this purpose, the following information will be
      processed:
    </p>

    <ul>
      <li>
        User information, such as name, email address, job title, company name;
      </li>
      <li>
        Business-related information which is not itself personal data but
        which, together with other information, may be personal data, such as
        such as number of developers in the company, issue tracker and source
        hosting service; and
      </li>
      <li>
        Information related to marketing communications through email, meetings,
        website and social media platforms.
      </li>
    </ul>

    <h3>2.3 Technical data</h3>

    <p>
      We collect some technical data automatically through the use of our
      website or Service, which may be associated with Users. This includes IP
      address, type and device ID of the User&apos;s device as well as mobile
      operating system, browser type, browser version, the specific sub-sites of
      our website that the User visits, the time and date of the visit, the time
      spent on those sites, unique device identifiers and other statistical
      data.
    </p>

    <h3>2.4 Special categories of personal data</h3>

    <p>
      We do not process special categories of personal data about our Users.
    </p>

    <h2>3. Purpose and legal basis for processing personal data</h2>

    <p>We process personal data for the following purposes:</p>

    <p>
      <strong>
        1) Providing the Service and managing customer relationship
      </strong>
    </p>

    <p>
      The primary purpose of processing personal data is to provide the Service
      and to manage and maintain the customer relationship between us and
      User/the company the User represents. In this case, our processing of
      personal data is based on the customer contract.
    </p>

    <p>
      <strong>2) Marketing</strong>
    </p>

    <p>
      We send Users emails to inform about new features of the Service, ask for
      feedback, to target advertising or provide them other relevant information
      about our Service. In this respect, processing of personal data is based
      on our legitimate interest to provide Users with relevant and tailored
      information of the Service, to promote the Service or to personalize the
      User experience. Users have the right to object to processing that is
      based on our legitimate interest, as indicated in Section 7 below.
    </p>

    <p>
      A User may unsubscribe from marketing communications at any time by
      clicking on the &quot;Unsubscribe&quot; or &quot;Update preferences&quot;
      link located on the bottom of emails or by contacting us at{' '}
      <a href="mailto:hello@swarmia.com">hello@swarmia.com</a>.
    </p>

    <p>
      We or our third-party service providers may place a cookie or similar
      technologies, such as a pixel tag on certain website. This enables us and
      our third-party service providers to display relevant advertisements to
      our Users online to re-engage Users who use our Service or previously have
      visited our website. This processing is based on User&apos;s consent.
      Users may make use of our cookie consent tool to withdraw their consent or
      to amend their choices which can be found by clicking “Manage Cookies” on
      the bottom of our website. Users may also configure browser or device to
      block the use of certain cookies.
    </p>

    <p>
      <strong>3) Service development and information security</strong>
    </p>

    <p>
      We also process personal data to improve the quality of the Service,
      including to monitor and analyse the use of the Service, and to ensure its
      security. In these cases, the legal basis for data processing is our
      legitimate interest to ensure that our Service has an adequate level of
      data security, and that we have sufficient and relevant information at
      hand to develop our Service.
    </p>

    <h2>4. Disclosures of personal data</h2>

    <p>We may disclose personal data to third parties:</p>
    <ul>
      <li>
        when permitted or required by law, e.g. to comply with requests by
        competent authorities or related to legal proceedings;
      </li>
      <li>
        when our trusted third-party service providers, such as cloud service
        providers and advertising management platforms, provide services to us.
        Some of the service providers are acting on our behalf as data
        processors and act under our instructions. However, we may also use
        service providers acting as controllers (e.g., lawyers, accountants,
        social media platforms etc.). In any case, the service providers are
        engaged for the purposes of pursuing the activities outlined in this
        Policy;
      </li>
      <li>
        if we are involved in a merger, acquisition, or sale of all or a portion
        of our assets; and
      </li>
      <li>
        when we believe in good faith that disclosure is necessary to protect
        our rights, protect safety of our Users or the safety of others or to
        investigate fraud.
      </li>
    </ul>

    <h2>5. Transfers of personal data outside of the EU/EEA</h2>

    <p>
      We may transfer personal data outside European Economic Area (
      <strong>&quot;EEA&quot;</strong>) when our trusted third-party service
      providers are established there. For example, for marketing purposes we
      use Google Ads, Microsoft Advertising, LinkedIn Campaign Manager, Twitter
      Ads, Facebook Ads, and Reddit Ads and these service providers may be
      located or otherwise process personal data from the U.S. outside of EEA.
      To the extent personal data is transferred to a country outside of the
      EU/EEA, Swarmia will use the required established mechanisms that allow
      the transfer to these service providers in those third countries, such as
      the Standard Contractual Clauses approved by the European Commission or
      another valid mechanism under the EU General Data Protection Regulation.
    </p>

    <h2>6. Retention of personal data</h2>

    <p>
      Swarmia will only retain personal data for as long as necessary to fulfill
      the purposes defined in this Policy. As a rule, the data will be processed
      during customer relationship. After that personal data will be retained
      for a short period before deleted to ensure that all contractual rights or
      obligations by either party are fulfilled, for example bills are paid, and
      shortly returning customers can retain their data. Some personal data may
      be retained longer when required by law, such as invoices and orders in
      accordance with accounting laws.
    </p>
    <p>
      When Swarmia processes personal data for marketing purposes, the data will
      be deleted after 2 years from the last contact between Swarmia and the
      User or when the User asks Swarmia to stop marketing and for a short
      period after this, allowing us to implement the request.
    </p>

    <h2>7. Data subject rights</h2>

    <p>Users have the following rights:</p>
    <ul>
      <li>
        The right to request access to personal data about himself/herself;
      </li>
      <li>
        The right to request rectification, restriction or erasure of personal
        data. However, certain information is strictly necessary in order to
        fulfil the purposes defined in this Policy and may also be required by
        law. Thus, it may not be possible to remove such personal data.
      </li>
      <li>
        The right to object for processing, that is based on legitimate
        interest;
      </li>
      <li>
        The right to object to processing for marketing purposes and prevent
        from receiving future direct marketing;
      </li>
      <li>
        If processing of personal data is based on consent, the User has the
        right to withdraw consent at any time. The withdrawal will not affect
        the lawfulness of the processing carried out before the withdrawal; and
      </li>
      <li>
        The right to data portability, i.e. right to receive the personal data
        in a structured, commonly used machine-readable format and transmit the
        personal data to another data controller, to the extent required by
        applicable law. This applies for personal data processed based on
        contract or the User&apos;s consent.
      </li>
    </ul>

    <p>
      Please send above-mentioned requests to us at{' '}
      <a href="mailto:hello@swarmia.com">hello@swarmia.com</a>.
    </p>

    <p>
      If you think there is a problem with the way we are handling your personal
      data, you have a right to file in a complaint to your national data
      protection authority in the EU/EEA. You may also file in a complaint to
      the data protection authority in any other EU country where you live,
      work, or where you think the alleged violation has occurred.
    </p>

    <h2>8. Security</h2>

    <p>
      We maintain reasonable security measures (including physical, electronic,
      and administrative) to protect personal data from loss, destruction,
      misuse, and unauthorized access or disclosure.
    </p>

    <p>
      Please be aware that, although we endeavour to provide reasonable security
      measures for personal data, no security system can prevent all potential
      security breaches.
    </p>

    <h2>9. Changes to this policy</h2>

    <p>
      We may change this Policy from time to time. If we make any changes to
      this Policy, we will actively bring it to the attention of the users by
      using the available communication channels. The most recent version of
      this Policy can be found at{' '}
      <a href="https://www.swarmia.com/privacy">
        https://www.swarmia.com/privacy
      </a>
      .
    </p>

    <div id="cookiepolicy" />
    <h1>Cookie Policy</h1>

    <p>
      A &quot;cookie&quot; is a piece of information that is stored on a
      user&apos;s computer, tablet or phone (hereinafter together
      &quot;device&quot;) when they visit a website or use a service. Other
      technologies classified here as cookies may be used in the same purpose.
      Cookies can help in identifying a user&apos;s device when they use a
      mobile application or visit a website and remember helpful details of the
      use and the choices made by the user to improve the user experience.
    </p>

    <p>
      Cookies cannot be used alone to identify an individual, but they may be
      linked directly or indirectly to an identifiable individual when combined
      with other information. In these cases, cookies may be treated as personal
      data. If we identify a user based on cookies, we make sure that all cookie
      information is processed as personal data in accordance with applicable
      data protection laws.
    </p>

    <p>
      Where Users give us their consent to place cookies and to use similar
      technologies, Users have the right to withdraw their consent at any time.
      Users may make use of our cookie consent tool to withdraw their consent or
      to amend their choices, which can be found by clicking “Manage Cookies” on
      the bottom of our website. Users may also prohibit the use of cookies by
      changing the browser settings. Users may also limit the use of cookies or
      remove cookies from the browser. However, cookies are an important part of
      how the website works, so limiting the use of cookies may affect the
      functionality of the website.
    </p>

    <p>We use both session and persistent cookies for various purposes.</p>

    <ul>
      <li>
        <i>Session cookies</i> are stored in memory for the duration of the
        session and removed once the User closes the browser.
      </li>
      <li>
        <i>Persistent cookies</i> are placed on hard drive of the User&apos;s
        computer and stored until they expire or the User deletes the cookies.
        We will retain information collected via persistent cookies no longer
        than 2 years.
      </li>
    </ul>

    <p>We use both first and third party cookies for various purposes.</p>

    <ul>
      <li>
        <i>First party cookies</i> refer to both session and persistent cookies
        placed by us on our website.
      </li>
      <li>
        <i>Third party cookies</i> are cookies placed by third parties on our
        website, such as Google Analytics, Segment, HubSpot, Facebook, Twitter,
        LinkedIn, and CookieHub.
      </li>
    </ul>

    <p>We use cookies as set out below:</p>

    <ul>
      <li>
        Essential cookies
        <ul>
          <li>Type: Session cookies</li>
          <li>Administered by us</li>
          <li>
            Purpose: These cookies are essential to provide the Swarmia website
            and to enable the use some of its features. They help to
            authenticate users and prevent fraudulent use of user accounts.
          </li>
        </ul>
      </li>
      <li>
        Notice acceptance cookies
        <ul>
          <li>Type: Persistent cookies</li>
          <li>Administered by us</li>
          <li>
            Purpose: These cookies identify if users have accepted the use of
            cookies on the Swarmia website.
          </li>
        </ul>
      </li>
      <li>
        Functionality cookies
        <ul>
          <li>Type: Persistent cookies</li>
          <li>Administered by us</li>
          <li>
            Purpose: These cookies allow us to remember choices a user makes
            when using the Swarmia website, such as remembering their login
            details or language preference. The purpose of these cookies is to
            provide a more personalised experience and to avoid a user having to
            re-enter their preferences every time they use the Swarmia website.
          </li>
        </ul>
      </li>
      <li>
        Tracking and performance cookies
        <ul>
          <li>Type: Persistent cookies</li>
          <li>Administered by third-parties</li>
          <li>
            Purpose: These cookies are used to track information about traffic
            to the Swarmia website, how Users use the website and to display
            relevant advertisements to Users. We may also use these cookies to
            test new advertisements, pages, features or new functionality of the
            website to see how our users react to them.
          </li>
        </ul>
      </li>
    </ul>

    <p>
      More detailed information on the type of cookies, their purposes and
      duration can be found in our cookie consent tool by clicking &quot;Manage
      Cookies&quot; on the bottom of our website.
    </p>
  </div>
)
