import React from 'react'
import Page from '../components/Page'
import Block from '../components/Block'
import Layout from '../components/Layout'
import { PrivacyPolicy } from '../components/PrivacyPolicy'

const PrivacyPage = () => (
  <Layout justifyCenter title="Privacy Policy">
    <Page>
      <Block size="medium">
        <PrivacyPolicy />
      </Block>
    </Page>
  </Layout>
)

export default PrivacyPage
